import HoneybadgerVue from '@honeybadger-io/vue';
import { ViteSSG } from 'vite-ssg'
import App from './App.vue';
import routerOptions from './router';
import store from './store';
import i18n from './i18n';
import { useRegisterSW } from 'virtual:pwa-register/vue'

// import { configureCompat } from 'vue';
// configureCompat({
//   RENDER_FUNCTION: false,
//   WATCH_ARRAY: false,
// });

export const createApp = ViteSSG(
  // the root component
  App,
  // vue-router options
  routerOptions,
  // function to have custom setups
  ({ app, router, routes, isClient, initialState }) => {
    // install plugins etc.
    if (isClient) {
      router.beforeResolve((to, from) => {
        const lang = to.params.lang || import.meta.env.VITE_APP_I18N_LOCALE;
        i18n.global.locale = lang;
      });

      if (!!import.meta.env.VITE_APP_HONEYBADGER_API_KEY) {
        app.use(HoneybadgerVue, {
          apiKey: import.meta.env.VITE_APP_HONEYBADGER_API_KEY,
          environment: import.meta.env.NODE_ENV,
        });
      }

      useRegisterSW({
        onRegistered(r) {
          r && setInterval(() => {
            r.update()
          }, 60 * 60 * 1000)
        }
      });
    }
    app.use(i18n);
    app.use(store);
  }
);
const app = createApp();

if (import.meta.env.DEV && !import.meta.env.SSR) {
  window.$vm = app;
}
