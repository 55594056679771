<template>
  <div class="main-menu" id="main-menu">
    <router-link
      class="menu-button"
      :class="isOpen ? 'menu-active' : ''"
      :to="{
        query: $route.query,
        hash: isOpen ? '' : '#main-menu',
      }"
      aria-label="Main menu"
      title="Menu"
    >
      <svg
        class="menu-icon"
        viewBox="0 0 100 80"
        width="20"
        height="20"
        alt="Menu"
      >
        <rect width="100" height="15" rx="4"></rect>
        <rect y="30" width="100" height="15" rx="4"></rect>
        <rect y="60" width="100" height="15" rx="4"></rect>
      </svg>
    </router-link>
    <nav>
      <div class="menu-item menu-item--elevation">
        <h3>
          <router-link :to="pathForLocale({ path: '/' })">
            What is my elevation?
          </router-link>
        </h3>
        <ul>
          <li>
            <router-link :to="pathForLocale({ path: '/map' })">
              Elevation map
            </router-link>
          </li>
          <!-- <li v-if="hasElevationValue">
            <a @click="shareImage">Elevation share image</a>
            <div class="link-note">For Instagram Stories</div>
          </li> -->
          <li>
            <a
              href="https://www.etsy.com/shop/WhatIsMyElevation"
              target="_blank"
            >
              Custom elevation t-shirts
            </a>
          </li>
        </ul>
      </div>
      <div class="menu-item menu-item--how-fast">
        <h3>
          <router-link :to="pathForLocale({ path: '/how-fast-am-i-going' })">
            How fast am I going?
          </router-link>
        </h3>
        <ul>
          <li>
            <router-link :to="pathForLocale({ path: '/speed-converter' })">
              Speed Converter
            </router-link>
          </li>
          <li>
            <router-link :to="pathForLocale({ path: '/speed-calculator' })">
              Speed Calculator
            </router-link>
          </li>
        </ul>
      </div>
      <div class="menu-item menu-item--where-am-i">
        <h3>
          <router-link :to="pathForLocale({ path: '/what-is-my-address' })">
            Where am I?
          </router-link>
        </h3>
        <ul>
          <li>
            <router-link :to="pathForLocale({ path: '/what-is-my-address' })">
              What is my address?
            </router-link>
          </li>
          <li>
            <router-link
              :to="pathForLocale({ path: '/what-is-my-neighborhood' })"
            >
              What neighborhood am I in?
            </router-link>
          </li>
          <li>
            <router-link :to="pathForLocale({ path: '/what-is-my-city' })">
              What city am I in?
            </router-link>
          </li>
          <li>
            <router-link
              :to="pathForLocale({ path: '/what-township-am-i-in' })"
            >
              What township am I in?
            </router-link>
          </li>
          <li>
            <router-link :to="pathForLocale({ path: '/what-is-my-county' })">
              What county am I in?
            </router-link>
          </li>
          <li>
            <router-link :to="pathForLocale({ path: '/what-is-my-state' })">
              What state am I in?
            </router-link>
          </li>
          <li>
            <router-link
              :to="pathForLocale({ path: '/what-is-my-intersection' })"
            >
              What intersection am I at?
            </router-link>
          </li>
          <li>
            <router-link :to="pathForLocale({ path: '/what-is-my-zipcode' })">
              What zip code am I in?
            </router-link>
          </li>
          <li>
            <router-link
              :to="
                pathForLocale({ path: '/what-indigenous-territories-am-i-in' })
              "
            >
              What indigenous territories am I in?
            </router-link>
          </li>
        </ul>
      </div>
      <div class="menu-item menu-item--more">
        <h3>More&hellip;</h3>
        <ul>
          <li>
            <router-link
              :to="pathForLocale({ path: '/what-school-district-am-i-in' })"
            >
              What school district am I in?
            </router-link>
          </li>
          <li>
            <router-link :to="pathForLocale({ path: '/what-zone-am-i-in' })">
              What plant hardiness zone am I in?</router-link
            >
          </li>
          <li>
            <router-link :to="pathForLocale({ path: '/county-map' })"
              >US county maps</router-link
            >
          </li>
          <li>
            <router-link :to="pathForLocale({ path: '/how-far-is-it' })">
              How far is it?
            </router-link>
          </li>
          <li>
            <router-link :to="pathForLocale({ path: '/snow-totals' })"
              >Snow totals</router-link
            >
          </li>
          <li>
            <a href="https://random-gifts.com/">Random Gifts</a>
          </li>
        </ul>
      </div>
      <div class="menu-item menu-item--settings">
        <h3>Mode</h3>
        <a
          v-for="colorScheme in ['light', 'dark', 'auto']"
          :key="colorScheme"
          :class="[
            'setting-button',
            `setting-button--color-scheme-${colorScheme}`,
            currentColorScheme === colorScheme ? 'active' : '',
          ]"
          @click="$store.dispatch('setColorScheme', colorScheme)"
          >{{ $t(`color-scheme-${colorScheme}`) }}</a
        >
      </div>
    </nav>
    <a
      class="backdrop"
      href="#menu-close"
      tabindex="-1"
      aria-hidden="true"
      hidden
    ></a>
  </div>
</template>
<script>
import { pathForLocale, round, encodeUrlTitle } from "@/helpers";

export default {
  computed: {
    hasElevationValue() {
      return this.$store.getters.hasElevationValue;
    },
    currentColorScheme() {
      return this.$store.state.colorScheme;
    },
    isOpen() {
      return this.$route.hash === "#main-menu";
    }
  },
  methods: {
    shareImage() {
      if (!this.hasElevationValue) {
        return;
      }
      const { location, elevation } = this.$store.state;
      this.$router.push({
        name: "ShareCustomizer",
        params: {
          latitude: `${round(location.latitude, 5)}`,
          longitude: `${round(location.longitude, 5)}`,
          title: encodeUrlTitle(location.title),
          elevation: `${round(elevation.value, 1)}`,
          lang: this.$i18n.locale === "en" ? null : this.$i18n.locale
        }
      });
    },
    pathForLocale
  }
};
</script>