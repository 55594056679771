<template>
  <Head>
    <title>{{ $t("site-title") }}</title>
    <html :lang="$i18n.locale" :class="`color-scheme--${colorScheme}`" />
    <link
      rel="preload"
      as="style"
      crossorigin="anonymous"
      :href="`https://use.typekit.net/${typekitId}.css`"
    />
    <link
      rel="stylesheet"
      media="print"
      onload='this.media="all"'
      crossorigin="anonymous"
      :href="`https://use.typekit.net/${typekitId}.css`"
    />
    <meta name="description" :content="$t('site-description')" />
    <meta name="og:title" :content="$t('site-title')" />
    <meta name="og:description" :content="$t('site-description')" />
    <meta name="og:image" :content="`${baseUrl}favicon.png`" />
  </Head>
  <Menu />
  <router-view />
</template>
<script>
import { Head } from "@vueuse/head";
import "@/assets/css/style.scss";
import { mapState } from "vuex";
import Menu from "@/components/Menu.vue";
export default {
  components: { Menu, Head },
  computed: {
    ...mapState(["colorScheme"]),
    typekitId() {
      return import.meta.env.VITE_APP_TYPEKIT_ID;
    },
    baseUrl() {
      return import.meta.env.BASE_URL;
    }
  }
};
</script>
