import axios from "axios";

const baseUrl = "https://dev.virtualearth.net/REST/v1";
const keys = import.meta.env.VITE_APP_BING_API_KEYS.split(",");

function getKey() {
  return keys[Math.floor(Math.random() * keys.length)];
}

async function getLocation({ latitude, longitude, lang = "en-US" }) {
  const params = {
    key: getKey(),
    includeNeighborhood: 1,
    culture: lang
  };
  const response = await axios.get(`${baseUrl}/Locations/${latitude},${longitude}`, { params });
  return response.data.resourceSets[0].resources[0].address;
}

async function getElevation({ latitude, longitude }) {
  if (Number.isNaN(latitude) || Number.isNaN(longitude)) {
    throw new Error("NaN");
  }

  let params = {
    key: getKey(),
    points: `${latitude},${longitude}`
  };
  let response = await axios.get(`${baseUrl}/Elevation/List/`, { params });
  return response.data.resourceSets[0].resources[0].elevations[0];
}

async function getAutosuggestions({ query, lang = "en-US" }) {
  let params = {
    key: getKey(),
    culture: lang,
    query,
    maxResults: 5
  };
  let response = await axios.get(`${baseUrl}/Autosuggest/`, { params });
  return response.data.resourceSets[0].resources[0].value;
}

async function getLocations({ query, latitude = null, longitude = null, lang = "en-US" }) {
  let params = {
    key: getKey(),
    includeNeighborhood: 1,
    culture: lang,
    query
  };
  if (latitude !== null && longitude !== null) {
    params.location = `${latitude},${longitude}`;
  }
  let response = await axios.get(`${baseUrl}/Locations/`, { params });
  let data = response.data.resourceSets[0].resources;
  return data;
}

async function getLocationForAutosuggestion({ item = null, lang = "en-US" }) {
  let params = {
    key: getKey(),
    includeNeighborhood: 1,
    culture: lang,
    query: item.name || undefined,
    ...item.address
  };
  let response = await axios.get(`${baseUrl}/Locations/`, { params });
  return response.data.resourceSets[0].resources[0];
}

export default {
  getElevation,
  getLocation,
  getLocations,
  getAutosuggestions,
  getLocationForAutosuggestion
};
